<template>
  <div class="contatain">
    <div class="content-wrap">
      <el-card class="row2-card">
        <el-table
          border
          :data="actionArr"
          :header-cell-style="thStyle"
          :cell-style="cellStyle"
          v-loading="loading"
        >
          <el-table-column prop="createName" label="操作人"> </el-table-column>
          <el-table-column prop="ip" label="操作人ip"></el-table-column>
          <el-table-column label="操作" prop="type"> </el-table-column>
          <el-table-column prop="result" label="操作结果"></el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next, sizes"
          :total="count"
          :page-size="params.limit"
          :current-page="params.page"
          :page-sizes="[5, 10, 15, 20]"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
import { timestampToDate } from "@tools";
export default {
  components: {},
  data() {
    return {
      loading: false,
      dictTypeList: [],
      params: {
        limit: 10,
        page: 1,
      },
      count: 0,
      editForm: null,
      actionArr: [
      ],
    };
  },
  async created() {
    // this.getDictTypeDataList();
    this.getAdminAction();
  },
  mounted() {},
  methods: {
    async getAdminAction() {
      this.loading = true;
      const { data, count } = await this.$api.log
        .getAdminAction(this.params)
        .finally(() => {
          this.loading = false;
        });
      this.actionArr = data;
      this.count = count;
    },
    handleSizeChange(size) {
      this.params.limit = size;
      this.getAdminAction();
    },
    handlePageChange(page) {
      this.params.page = page;
      this.getAdminAction();
    },
    transTime(time) {
      return timestampToDate(time);
    },
    openDialog(ref, form) {
      if (form) {
        const { dictName, dictType, id, remark } = form;
        this.editForm = { dictName, dictType, id, remark };
      } else {
        this.editForm = null;
      }
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
</style>